import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Då var vi framme till ännu en fredag som idag är fullspäckad med free spins erbjudande. Då det var en del framgångar i OS under gårdagen med en silvermedalj så kommer vi även idag att ta emot en hel del special bonusar. Sen så får vi även hoppas på att Sverige gör många mål emot Finland idag då Unibet kommer att ge ett free spin på South Park för varje mål som Sverige gör.`}</p>
    <h2>{`Unibet`}</h2>
    <p>{`Då Sverige lyckades att ta en silver medalj i OS igår så betyder det att Unibet ger idag alla sina spelare 5 free spins på Gonzo Quest idag. Sen så får vi hoppas på att Sverige gör många mål emot Finland idag då Unibet kommer att ge ett free spin per mål som Sverige gör i matchen.`}</p>
    <p>{`Vill man öka spänning något mera och få ännu mera free spins så kan man lägga ett spel för minst 100kr på matchen mellan Sverige och Finland, då kommer man att få 10 free spins på Starburst och skulle Sverige vinna så får man 20 istället.`}</p>
    <p>{`Hämta 5 free spins på GonzoQuest – Spela Nu!`}</p>
    <h2>{`Gratis spinn 21 februari 2014`}</h2>
    <p>{`Bertil – Spela för minst 100kr eller mera i mobilen och få 20 free spins på ett av följande spel i mobilen. Starburst eller Flowers, Fruit Shop.
Till Bertil och Hämta 20 free spins`}</p>
    <p>{`RedBet – Som ny kund så tar man emot 50 free spins direkt utan några krav på insättning. Man får 10 free spins på 5 följande automater, Starburst, Twin Spin, Creature from the Black Lagoon, Secret of the Stones™ och Gonzo’s Quest.
Hämta 50 free spins direkt! Spela nu!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      